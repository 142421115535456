import mailIcon from './src/at-solid.svg'
import addressIcon from './src/location-dot-solid.svg'
import telIcon from './src/phone-solid.svg'

import close from './src/x-solid.svg'

import './about.scss'

import { useDispatch } from 'react-redux'
import { setShowAbout } from "../../../../redux/mainPageSlice"

export default function About() {
	const dispatch = useDispatch()
	const handleAbout = () => {
		dispatch(setShowAbout(false))
		const event = new CustomEvent('liquid', { detail: false });
		window.dispatchEvent(event);
	}
	return (
		<>
			<div className="about-page" id="about">
				<img className="close" height={20} src={close} alt="close" style={{ position: "fixed", right: "50px", top: "50px" }} onClick={handleAbout} />
				<div className="d-md-flex ">
					<div>
						<h1>Mudakikwa Julio Fred</h1>
						<div className="about-description">
							<p>I am an experienced  Fullstack developer, working both on Web applications. I always strive to produce clean, robust, and highly tested code for every project. Having a very broad experience with numerous tools/languages and technologies, I specialize in building modern web applications with specialization in building breakthrough experiences with Threejs.
								With a broad understanding of the production pipeline from design to the final product deployment, I can easily fit into your team. Want to learn more about me Let us talk.</p>
							<div className="contact-list">
								<div className="contact">
									<img src={addressIcon} height={20} alt='address' />
									<p>Kigali,Rwanda</p>
								</div>
								<div className="contact">
									<img src={mailIcon} height={20} alt='email' />
									<p>fredmudakikwa@gmail.com</p>
								</div>
								<div className="contact">
									<img src={telIcon} height={20} alt='telephone' />
									<a href="tel:+250723870148"><p>+250723870148</p></a>
								</div>
							</div>
						</div>
					</div>
					<div className="me-img"></div>
				</div>
			</div>
		</>
	)
}
