import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger);

export const homeAnimation = () => {
    return gsap.timeline({
        scrollTrigger: {
            trigger: "#home-page",
            start: "top center",
            toggleActions: "restart pause restart pause",
            invalidateOnRefresh: true
        }
    })
    .fromTo(".small-text", {
        y: 30,
        opacity:0,
    }, {
        duration: 0.5,
        y: 0,
        opacity: 1,
    },
    0.5
    )
    .fromTo(".big-text,.developer", {
        y: 200,
        opacity: 0,
    }, {
        duration: 1.5,
        ease: "power4.out",
        y: 0,
        stagger: 0.3,
        opacity: 1,
    },
    0
    )
    .fromTo(".text-desc", {
        x: 50,
        opacity: 0,
    }, {
        duration: 1.5,
        ease: "power4.out",
        x: 0,
        opacity: 1,
    },
    0.15
    )
    .fromTo(".btn-red-overflow,.btn-red-overflow-1", {
        opacity: 0,
        x:30,
    }, {
        duration: 1.5,
        ease: "power4.out",
        opacity: 1,
        x:0,
        stagger:0.2,
    },
    1.1
    )
    .fromTo(".social-media > svg,.logo", {
        opacity:0,
        x:-50,
    }, {
        duration: 0.5,
        ease: "power4.inOut",
        stagger:0.1,
        opacity: 1,
        x: 0,
    },
    "<0"
    )
    // timeline.start()
}