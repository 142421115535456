import { useEffect } from 'react'
import Project from './singleProject'

import { projectsAnimation } from "./Animations"

import Client from './src/Client'
import Plus from './src/Plus'
import Like from './src/Like'
import guez from './src/guez.PNG'

export default function Projects() {
  useEffect(() => {
    projectsAnimation()
  }, [])
  const image = 'https://mudakikwa.s3.eu-west-2.amazonaws.com/mudakikwa.com/60765482.jpg'

  return (
	<div className="projectspage" data-scroll-section id="all-projects">
    <div className="projects-list" id="myProjects">
        <h3 className="work-text">My Works</h3>
        <h6 className="text-desc-work">With an increasing  competitive market my focus is delivering the top-notch user experience - giving your business the competitive edge, check my work it say it better.</h6>
        <Project id="project1" client="Guez Show" projectId={1} url="https://guezshow.com/" description={`I helped build the website of guezshow. I participated in the initial UI and UX concept to the final implementation of the website. Kudos to the team from guezshow for the great talent. They also brought a lot to the table, including constructive design changes and the final 3d renders and content for the website.`} tags={["UI Design", "Frontend Dev", "GLSL Dev", "Backend Dev"]} mobileImage="https://mudakikwa.s3.eu-west-2.amazonaws.com/mudakikwa.com/iPhone+13,+12+Pro+Max+–+1.webp" />
        <Project id="project2" client="Leerecs" projectId={2} url="http://leerecs.com/" description={`Leerecs is one of my great solo projects with great supervision from an industry veteran. I helped modernize the website of leerecs and gave it a modern feel , I helped design the initial UI and UX , and later moved to the implementation of both the frontend and backend of their new website.`} tags={["UI Design", "Frontend Dev", "Backend Dev", "GLSL Dev"]} mobileImage="https://mudakikwa.s3.eu-west-2.amazonaws.com/mudakikwa.com/iPhone+13,+12+Pro+Max+–+2.webp" />
        <Project id="project3" client="SJ Cars" projectId={3} url="https://sjcars.herokuapp.com/" description={`SJ cars is another solo project I made for a local client here in Rwanda. I helped establish their new website for car sales and booking ,with a stronger focus on bringing forward the car aesthetics to the buyer before  they purchase a car. During my time with SJ Cars, I designed the UI and UX for their website and implemented both the backend and frontend for the new website.`} tags={["UX Research", "UI Design", "Frontend Dev", "Backend Dev"]} mobileImage="https://mudakikwa.s3.eu-west-2.amazonaws.com/mudakikwa.com/iPhone+13,+12+Pro+Max+–+3.webp" />
        <Project id="project4" client="Logic Coded" projectId={4} url="https://www.behance.net/gallery/122320891/logic-coded-ui-design" description={`Logic Coded is one of the most interesting projects I have laid my hands on with a company that was focused on making dev life easier by designing systems for devs to easily reuse their codes. I helped design the initial UI concepts and assisted in the frontend development of their app.`} tags={["UI Design", "Concept Design", "Frontend Dev", "Electron Dev"]} mobileImage="https://mudakikwa.s3.eu-west-2.amazonaws.com/mudakikwa.com/iPhone+13,+12+Pro+Max+–+4.webp" />
    </div>

    <div className="traits">
  		<div className="title"><span>01</span>Recent Works</div>
      <div className="circles">
        <div className="client-list">
            <Client image="https://mudakikwa.s3.eu-west-2.amazonaws.com/mudakikwa.com/guezshow.jpg" projectId={1}/>
            <Client image="https://mudakikwa.s3.eu-west-2.amazonaws.com/mudakikwa.com/NoPath.png" projectId={2}/>
            <Client image="https://mudakikwa.s3.eu-west-2.amazonaws.com/mudakikwa.com/sjacrs.jpg" projectId={3}/>
            <Client image="https://mudakikwa.s3.eu-west-2.amazonaws.com/mudakikwa.com/logicoded.jpg" projectId={4}/>
        </div>
      </div>
  	</div>
  </div>
  )
}
