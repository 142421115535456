import React from 'react'
import { useSelector } from 'react-redux'

export default function Client(props) {
  const currentProject = useSelector((state) => state.mainPageSliceReducer.currentProject)
  const isActive=()=>{
    if (currentProject === props.projectId){
      return "client-active"
    }
    else{
      return "client"
    }
  }
  return (
    <div className={`client single-circle rounded-circle ${isActive()}`} style={{
		backgroundImage: `url(${props.image})`,
	}}>
    </div>
  )
}
