import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
export const projectsAnimation = () => {
	// var tl = gsap.timeline();

	ScrollTrigger.create({
		trigger: ".traits",
		start: "bottom bottom",
		end: `+=${window.innerHeight * 3.5}`,
		anticipatePin: 1,
		scrub: 1,
		pin: true,
	});
};
// scrollTrigger: {
// 	trigger: `#${id}`,
// 		start: "top center",
// 			toggleActions: "restart pause restart pause",
// 				onEnter: () => {
// 					setProjectId()
// 				},
// 					onEnterBack: () => {
// 						setProjectId()
// 					},
// 		},
export const projectAnimation = (id, setProjectId) => {
	let projectId = document.querySelector(`#${id}`)
	return gsap.timeline({
		scrollTrigger: {
			trigger: projectId,
			start: "top center",
			// toggleActions: "restart pause restart pause",
			onEnter: () => {
				setProjectId();
			},
			onEnterBack: () => {
				setProjectId();
			},
			invalidateOnRefresh:true
		}
	})
		.fromTo(
			`#text1${id}`,
			{
				y: 100,
				rotationX:100,
				opacity: 0,
			},
			{
				duration: 0.4,
				opacity: 1,
				rotationX: 0,
				y: 0,
			}
		)
		.fromTo(
			`#text2${id}`,
			{
				y: 100,
				opacity: 0,
				rotationX: 100,
			},
			{
				duration: 0.4,
				opacity: 1,
				rotationX: 0,
				y: 0,
			},
			"<"
		)
		.fromTo(
			`.tag${id}`,
			{
				opacity: 0,
				
			},
			{
				x: 0,
				duration: 0.2,
				opacity: 1,
				stagger: 0.1,
			},
			"<0.1"
		)
		.fromTo(
			`#text3${id}`,
			{
				opacity: 0,
			},
			{
				duration: 0.5,
				opacity: 1,
				stagger: 0.1,
			},
			">"
		)
};
