import React from 'react'

export default function Play() {
  return (
      <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 70 70">
          <g id="Group_8" data-name="Group 8" transform="translate(-506 -791)">
              <circle id="Ellipse_1" data-name="Ellipse 1" cx="25" cy="25" r="25" transform="translate(516 801)" fill="#000000" />
              <g id="Ellipse_2" data-name="Ellipse 2" transform="translate(511 796)" fill="none" stroke="#000000" strokeWidth="1" opacity="0.5">
                  <circle cx="30" cy="30" r="30" stroke="none" />
                  <circle cx="30" cy="30" r="29.5" fill="none" />
              </g>
              <g id="Ellipse_3" data-name="Ellipse 3" transform="translate(506 791)" fill="none" stroke="#000000" strokeWidth="1" opacity="0.3">
                  <circle cx="35" cy="35" r="35" stroke="none" />
                  <circle cx="35" cy="35" r="34.5" fill="none" />
              </g>
              <g id="play_arrow" transform="translate(525 811)">
                  <g id="Group_7" data-name="Group 7" transform="translate(8 5)">
                      <path id="Path_29" data-name="Path 29" d="M8,5V25L23.714,15Z" transform="translate(-8 -5)" fill="#f0f2f2" />
                  </g>
              </g>
          </g>
      </svg>

  )
}
