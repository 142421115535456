import { useEffect, useRef } from 'react'

import Play from './src/Play'
// import Traits from './src/Traits'
import { homeAnimation } from "./Animations"
import { useSelector, useDispatch } from "react-redux"
import { setReel } from '../../../../redux/mainPageSlice'
import lottie from 'lottie-web';

import "./home.scss"

export default function Home() {
    const ref = useRef(null)
    const percentage = useSelector((state) => state.mainPageSliceReducer.percentage)
    const dispatch = useDispatch()
    const handleReel = () => {
        dispatch(setReel(true))
    }
    useEffect(() => {
        let tl = null
        if (percentage === 100 && window.innerWidth > 600) {
            setTimeout(() => {
                tl = homeAnimation()
            }, 2000);
        }
        if (percentage === 100) {
            const windowUrl = window.location.href
            if (windowUrl.includes("footer")) {
                window.scrollTo(0, document.body.scrollHeight);
            }
        }
        return () => tl?.scrollTrigger?.kill()
    }, [percentage])

    const animationContainer = useRef()
    useEffect(() => {
        const anim = lottie.loadAnimation({
            container: animationContainer.current,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            path: "https://mudakikwa.s3.eu-west-2.amazonaws.com/mudakikwa.com/lf30_editor_xjn26o3a.json",
        });
        return () => anim.destroy(); // optional clean up for unmounting
    }, [animationContainer]);

    return (
        <div ref={ref} id="home-page">
            <div></div>
            <div className="homepage">
                <div className="cta">
                    <div className="small-overflow" id="home-text">
                        <div className="small-text opacity unselectable">Let us create a new story together.</div>
                    </div>
                    <div className="big-text-overflow">
                        <div className="big-text opacity unselectable">I build cool</div>
                    </div>
                    <div className="big-text-2">
                        <div className="big-text-overflow-2 unselectable"><div className="developer opacity">websites</div></div>
                        <div className="text-desc opacity unselectable">Hello there my name is Fred Julio
                            and i am professional designer and developer combining these knowledge to create exceptional designs and strategies tailored to your business needs and requirements.
                            <br />
                            <br />
                            My specialty is the development of web app projects and interactive websites.
                        </div>
                        <div className="d-flex ctas">
                            <div className="btn-red-overflow opacity">
                                <a href="#footer">
                                    <div className="btn-red">
                                        <div className="btn-red-text unselectable">Book Meeting</div>
                                    </div>
                                </a>
                            </div>
                            <div className="btn-red-overflow-1 opacity">
                                <div className="d-flex" onClick={() => {
                                    handleReel()
                                }}>
                                    <Play />
                                    <div className="play-text unselectable">Play Show Reel</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {window.innerWidth > 600 && (<div className="bottom-elements">
                <div className="drag-info d-flex">
                    <div className="lottie-container">
                        <div className="drag-lottie" ref={animationContainer}></div>
                    </div>
                    <div className="drag-text">Drag To Rotate</div>
                </div>
            </div>)}

        </div >
    )
}
