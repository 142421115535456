import * as THREE from "three"

export const liquidShader = {
    uniforms: {
        "tDiffuse": { value: null },
        "u_time": { value: 0.0 },
        "u_liquidIntensity":{value:0.0},
        "u_resolution": { value: new THREE.Vector2(window.innerWidth, window.innerHeight)}
    },
    vertexShader: `
 varying vec2 vUv;
  void main() {
    vUv = uv;
    gl_Position = projectionMatrix 
      * modelViewMatrix 
      * vec4( position, 1.0 );
  }`,
    fragmentShader: `
    #ifdef GL_ES
precision mediump float;
#endif

uniform vec2 u_resolution;
uniform float u_time;
uniform sampler2D tDiffuse;
uniform float u_liquidIntensity;
varying vec2 vUv;
float colormap_red(float x){
    if (x < 0.) {
        return 54. / 255.;
    } else if (x < 20049. / 82979.) {
        return (829.79 * x + 54.51) / 255.;
    } else {
        return 1.;
    }
}

float colormap_green(float x){
    if (x < 20049. / 82979.) {
        return 0.;
    } else if (x < 327013. / 810990.) {
        return (8546482679670. / 10875673217. * x - 2064961390770. / 10875673217.) / 255.;
    } else if (x <= 1.) {
        return (103806720. / 483977. * x + 19607415. / 483977.) / 255.;
    } else {
        return 1.;
    }
}

float colormap_blue(float x){
    if (x < 0.) {
        return 54. / 255.;
    } else if (x < 7249. / 82979.) {
        return (829.79 * x + 54.51) / 255.;
    } else if (x < 20049. / 82979.) {
        return 127. / 255.;
    } else if (x < 327013. / 810990.) {
        return (792.02249341361393720147485376583 * x - 64.364790735602331034989206222672) / 255.;
    } else {
        return 1.;
    }
}

vec4 colormap(float x){
    return vec4(colormap_red(x), colormap_green(x), colormap_blue(x), 1.);
}

// https://iquilezles.org/articles/warp
/*float noise( in vec2 x )
{
    vec2 p = floor(x);
    vec2 f = fract(x);
    f = f*f*(3.0-2.0*f);
    float a = textureLod(iChannel0,(p+vec2(0.5,0.5))/256.0,0.0).x;
    float b = textureLod(iChannel0,(p+vec2(1.5,0.5))/256.0,0.0).x;
    float c = textureLod(iChannel0,(p+vec2(0.5,1.5))/256.0,0.0).x;
    float d = textureLod(iChannel0,(p+vec2(1.5,1.5))/256.0,0.0).x;
    return mix(mix( a, b,f.x), mix( c, d,f.x),f.y);
}*/

float rand(vec2 n){
    return fract(sin(dot(n, vec2(12.9898, 4.1414))) * 43758.5453);
}

float noise(vec2 p){
    vec2 ip = floor(p);
    vec2 u = fract(p);
    u = u * u * (3. - 2. * u);
    
    float res = mix(
        mix(rand(ip), rand(ip + vec2(1., 0.)), u.x),
        mix(rand(ip + vec2(0., 1.)), rand(ip + vec2(1., 1.)), u.x), u.y);
    return res * res;
}

const mat2 mtx = mat2(.80, .60, -.60, .80);
    
    float fbm(vec2 p)
{
        float f = 0.;

    f += .500000 * noise(p + u_time); p = mtx * p * 2.02;
    f += .031250 * noise(p); p = mtx * p * 2.01;
    f += .250000 * noise(p); p = mtx * p * 2.03;
    f += .125000 * noise(p); p = mtx * p * 2.01;
    f += .062500 * noise(p); p = mtx * p * 2.04;
    f += .015625 * noise(p + sin(u_time));

    return f / .96875;
}
    
    float pattern(vec2 p)
{
    return fbm(p + fbm(p + fbm(p)));
}
    
    vec2 rotate(vec2 v, float a){
        float s = sin(a);
        float c = cos(a);
        mat2 m = mat2(c, -s, s, c);
    return m * v;
}
    float normpdf(in float x,in float sigma)
{
    return .39894 * exp(-.5 * x * x / (sigma * sigma)) / sigma;
}
void main()
{
    vec2 uv = vUv;
    float shade = pattern(vUv);
    uv.x*=cos(shade);
    uv.y*=sin(shade);
    vec4 shadeColor=vec4(colormap(shade).rgb,1);
    vec4 color = texture2D( tDiffuse, uv );
    vec3 finalColor=mix(shadeColor.rgb,color.rgb,shade);
    gl_FragColor= color;
}
    `
}



