import React from 'react'
import "./index.scss"

export default function Award() {
  return (
    <a href="https://www.cssdesignawards.com/sites/mudakikwa-julio-fred-portfolio/41900/" target="_blank" rel="noopener noreferrer">
      <div id="award">
        <img src="./award/best-innovation-white.png" alt="" />
        <img src="./award/best-ui-white.png" alt="" />
        <img src="./award/best-ux-white.png" alt="" />
      </div>
    </a>
  )
}
