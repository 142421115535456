import * as THREE from "three"

export const liquidBackShader = new THREE.ShaderMaterial({
    uniforms: {
        u_time: {value: 1.0},
        u_resolution: {value: new THREE.Vector2(window.innerWidth,window.innerHeight)},
        colorB: { value: new THREE.Color("#B8ABA1") },
        colorA: { value: new THREE.Color("#000000") },
    },
    vertexShader: `
 varying vec2 vUv;
  void main() {
    vUv = uv;
    gl_Position = projectionMatrix 
      * modelViewMatrix 
      * vec4( position, 1.0 );
  }`,
    fragmentShader: /* glsl */`
     #ifdef GL_ES
precision mediump float;
#endif

uniform vec2 u_resolution;
uniform float u_time;
uniform vec3 colorA;
uniform vec3 colorB;
varying vec2 vUv;
const int amount=12;

float norm(float mouseX,float mouseY){
    return(mouseX+mouseY);
}
vec3 palette(in float t,in vec3 a,in vec3 b,in vec3 c,in vec3 d)
{
    return a+b*cos(6.28318*(c*t+d));
}
vec3 toGrayscale(in vec3 color)
{
    float average=(color.r+color.g+color.b)/3.;
    return vec3(average,average,average);
}

float rand(vec2 n){
    return fract(sin(dot(n, vec2(12.9898, 4.1414))) * 43758.5453);
}

float noise(vec2 p){
    vec2 ip = floor(p);
    vec2 u = fract(p);
    u = u * u * (3. - 2. * u);
    
    float res = mix(
        mix(rand(ip), rand(ip + vec2(1., 0.)), u.x),
        mix(rand(ip + vec2(0., 1.)), rand(ip + vec2(1., 1.)), u.x), u.y);
    return res * res;
}

void main(){
    vec2 coord=1.*((gl_FragCoord.xy-u_resolution)/2.)/min(u_resolution.y,u_resolution.x);
    
    float len;
    
    
    // coord=coord+speed;
    for(int i=0;i<amount;i++){
        len=length(vec2(coord.x,coord.y));
        coord.x=coord.x-sin(coord.y)*cos(coord.y+sin(len))+(cos(u_time/9.)/1.2)+(noise(vec2(coord.x,u_time/100.0))/1.0);
        coord.y=coord.y+cos(coord.x)*sin(coord.x+cos(len))+(sin(u_time/12.)/1.2)+(noise(vec2(coord.y,u_time/100.0))/1.0);
    }
    // vec3 pallette=palette(.5,vec3(.5,.5,.5),vec3(.0588,.0431,.0431),vec3(.1137,.102,.102),vec3(.4392,.0039,.4549));
    vec3 color=vec3(cos(len),cos(len),.3);
    // color=mix(color,pallette,.4);
    color=toGrayscale(color)/3.333333333333;
    vec3 colora=mix(colorB,colorA,color.r);
//     color*=mix(color,colorA,.9);
// color/=mix(color,colorA,.9)/vec3(3.);
// color*=mix(color,colorB,.9)*vec3(.4627,.4902,.502);
    colora=mix(colora,vec3(0.7),.5);
    gl_FragColor=vec4(colora,1.);
}     
    `
})



