import React, { useEffect, useRef } from "react";
import Lottie from 'react-lottie';
import lottie from 'lottie-web';
import "./Prelaoder.scss"

import { useSelector } from 'react-redux'

const Preloader = () => {
    const animationContainer=useRef()
    useEffect(() => {
        const anim = lottie.loadAnimation({
            container: animationContainer.current,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            path: "https://mudakikwa.s3.eu-west-2.amazonaws.com/mudakikwa.com/intro.json",
        });
        return () => anim.destroy(); // optional clean up for unmounting
    }, [animationContainer]);

    const percentage = useSelector((state) => state.mainPageSliceReducer.percentage)
    return (
        <div id="preloader" style={{ animationName:`${!(percentage<100) && "removePreloader"}`}}>
            <div
                style={{ width: "400px", height: "400px" }}
                ref={animationContainer}
            />
            <div className="loader">
                <div className="memoji rounded-circle"></div>
                <div className="percentage">
                    <div className="progress-percentage" style={{ minWidth: `${percentage}%`, maxWidth: `${percentage}%`}}></div>
                </div>
                <div className="loading-text">Loading Experience {percentage}%</div>
            </div>
        </div>)
};

export default Preloader;