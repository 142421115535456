import React from 'react'

export default function Linkedin() {
  return (
      <svg xmlns="http://www.w3.org/2000/svg" width="31.5" height="31.5" viewBox="0 0 31.5 31.5">
          <g id="linkedin" transform="translate(-2.25 -2.25)">
              <g id="Group_17" data-name="Group 17" transform="translate(3 3)">
                  <path id="Path_36" data-name="Path 36" d="M33,11.333V24.667A8.333,8.333,0,0,1,24.667,33H11.333A8.333,8.333,0,0,1,3,24.667V11.333A8.333,8.333,0,0,1,11.333,3H24.667A8.333,8.333,0,0,1,33,11.333Z" transform="translate(-3 -3)" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                  <path id="Path_37" data-name="Path 37" d="M7,21.667V10" transform="translate(-0.333 1.667)" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                  <path id="Path_38" data-name="Path 38" d="M11,21.667V16.25M11,10v6.25m0,0c0-6.25,10-6.25,10,0v5.417" transform="translate(2.333 1.667)" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                  <path id="Path_39" data-name="Path 39" d="M7,7.017,7.017,7" transform="translate(-0.333 -0.334)" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
              </g>
          </g>
      </svg>

  )
}
