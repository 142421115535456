import Home from './components/home/Home'
import Projects from './components/projects/Projects'
import About from './components/about/About'
import Footer from './components/footer/Footer'

import Layout from '../Layout'

import { useSelector } from 'react-redux'
import Award from './components/award/Award'

export default function MainPage() {
    const showAbout = useSelector((state) => state.mainPageSliceReducer.showAbout)
    return (
        <Layout>
            <Home />
            <Projects />
            {showAbout && <About />}
            <Footer />
            <Award />
            {/* <About /> */}
        </Layout>
    )
}
