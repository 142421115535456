import { Cursor } from 'react-creative-cursor';
import './styles.scss';

export default function CursorComponent() {
  return (
    <>
      <Cursor isGelly={true} cursorBackgrounColor={"#fff"}  text="hello" />
    </>
  )
}
