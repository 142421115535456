import React, { useRef, useEffect } from 'react'
import Close from './src/Close'
import "./Reel.scss"
import { useDispatch } from 'react-redux'
import {setReel} from "../../redux/mainPageSlice"
export default function Reel() {
    const video = useRef(null)
    const dispatch=useDispatch()
    const closeReel=()=>{
        dispatch(setReel(false))
    }
    useEffect(() => {

    }, [])

    return (
        <div id="reel">
            <div>
                <div className="d-flex justify-content-between close-btn" onClick={closeReel}>
                    <div></div>
                    <Close />
                </div>
                <div className="video-container">
                    <video src="https://mudakikwa.s3.eu-west-2.amazonaws.com/mudakikwa.com/merged.webm" ref={video} autoPlay muted loop controls></video>
                </div>
            </div>
        </div>
    )
}
