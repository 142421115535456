import React from 'react'

export default function Github() {
  return (
      <svg xmlns="http://www.w3.org/2000/svg" width="31.5" height="31.5" viewBox="0 0 31.5 31.5">
          <g id="github-outline" transform="translate(-1.25 -1.25)">
              <g id="Group_16" data-name="Group 16" transform="translate(2 2)">
                  <path id="Path_33" data-name="Path 33" d="M17,32A15,15,0,1,0,2,17,15,15,0,0,0,17,32Z" transform="translate(-2 -2)" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                  <path id="Path_34" data-name="Path 34" d="M18.5,25.5V22.706a3.206,3.206,0,0,0-.189-1.369,3.27,3.27,0,0,0-.75-1.171C20.7,19.824,24,18.665,24,13.349A5.22,5.22,0,0,0,22.5,9.7a4.816,4.816,0,0,0-.09-3.672S21.23,5.685,18.5,7.467a13.728,13.728,0,0,0-7,0C8.771,5.685,7.59,6.027,7.59,6.027A4.816,4.816,0,0,0,7.5,9.7,5.223,5.223,0,0,0,6,13.376c0,5.278,3.3,6.436,6.44,6.816a3.27,3.27,0,0,0-.744,1.16,3.2,3.2,0,0,0-.2,1.353v2.8" transform="translate(0 0)" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                  <path id="Path_35" data-name="Path 35" d="M12,18.676a5.4,5.4,0,0,1-7-2.922" transform="translate(-0.5 4.877)" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
              </g>
          </g>
      </svg>

  )
}
