import React, { useEffect } from 'react'
import "./Sound.scss"
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { useSelector, useDispatch } from "react-redux"

gsap.registerPlugin(ScrollTrigger);
export default function Sound() {
    const percentage = useSelector((state) => state.mainPageSliceReducer.percentage)
    const playAudio = useSelector((state) => state.mainPageSliceReducer.playAudio)
    useEffect(() => {
        if ((percentage === 100) && playAudio) {
            var tl = gsap.timeline({ repeat: -1 });
            tl.fromTo(".line1", {
                minHeight:"0px",
            }, {
                duration: 0.1,
                minHeight: "30px",
            },
            0
            )
            tl.fromTo(".line2", {
                minHeight: "0px",
            }, {
                duration: 0.3,
                minHeight: "30px",
            },
            ">0.1"
            )
            tl.fromTo(".line3", {
                minHeight: "0px",
            }, {
                duration: 0.15,
                minHeight: "30px",
            },
            ">0.1"
            )
        }
    }, [percentage, playAudio])
    return (
        <div id="soundAnimation">
            <div className="line1"></div>
            <div className="line2"></div>
            <div className="line3"></div>
        </div>
    )
}
