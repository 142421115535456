import React, { memo } from 'react'
import Preloader from './preloader/Preloader'
import Webgl from './Webgl/Webgl'
import Cursor from './main/components/cursor/Cursor'

function DesktopComponent({ show }) {
    return (
        <>
            {show && (<>
                {console.log("no preloader")}
                <Preloader />
                <Webgl />
                <Cursor />
            </>)}
        </>
    )
}

export default memo(DesktopComponent)
