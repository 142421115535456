import React, { useEffect, useRef } from 'react'
import * as THREE from "three"
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader'
// postprocessing
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { RGBShiftShader } from "three/examples/jsm/shaders/RGBShiftShader.js";
import { DragControls } from 'three/examples/jsm/controls/DragControls.js';
import { liquidShader } from "./liquid"
import { liquidBackShader } from "./backLiquid"
// controls
import "./index.scss"

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

// state controls
import { useDispatch } from 'react-redux'
import { setPercentage } from "../../redux/mainPageSlice"
import Stats from 'stats.js'

export default function Webgl() {
    gsap.registerPlugin(ScrollTrigger);
    let container = useRef()
    const dispatch = useDispatch()

    // refs
    const sceneRef = useRef()
    const cameraRef = useRef()
    const rendererRef = useRef()
    const loadingManagerRef = useRef()
    const composerRef = useRef()
    const liquidPassRef = useRef()
    const liquidMaterialRef = useRef()
    const fogRef = useRef()
    const countRef = useRef(0)

    useEffect(() => {
        let counter = 0.0;
        countRef.current += 1
        const clock = new THREE.Clock();
        var stats = new Stats();
        stats.showPanel(0); // 0: fps, 1: ms, 2: mb, 3+: custom

        // document.body.appendChild(stats.dom);
        const triggerAnimation = () => {
            function animateElement(id, position) {
                if (sceneRef.current.children.length === 3) {
                    gsap.registerPlugin(ScrollTrigger);
                    gsap.to(sceneRef.current.children[sceneRef.current.children.length - 1].children[1].rotation, {
                        y: THREE.MathUtils.degToRad(360),
                        duration: 2.5,
                        ease: "power4.inOut",
                        scrollTrigger: {
                            trigger: id,
                            start: "top center",
                            toggleActions: "restart pause reverse pause",
                        }
                    })
                    gsap.fromTo(sceneRef.current.children[sceneRef.current.children.length - 1].children[1].position, { y: position.from }, {
                        y: position.to,
                        duration: 2,
                        ease: "power4.inOut",
                        scrollTrigger: {
                            trigger: id,
                            start: "top center",
                            toggleActions: "restart pause reverse pause",
                            onEnter: () => {
                                // camera.position.z-=-3
                                // composer.removePass(liquidPass);
                                // composer.addPass(liquidPass);
                            },
                            onLeaveBack: () => {
                                composerRef.current.removePass(liquidPassRef.current);
                            }
                        }
                    })
                }
            }
            animateElement("#home-text", { from: 2.9, to: 3 })
            animateElement("#project1", { from: 3, to: 6 })
            animateElement("#project2", { from: 6, to: 9 })
            animateElement("#project3", { from: 9, to: 12 })
            animateElement("#project4", { from: 12, to: 15 })
        }
        const introAnimation = () => {
            gsap.registerPlugin(ScrollTrigger);
            const tl = gsap.timeline({
                scrollTrigger: {
                    trigger: "#home",
                    start: "top center", // or wherever you want the animation to start
                    // other ScrollTrigger options
                },
            });
            tl.fromTo("#preloader", {
                x: 0,
            }, {
                duration: 0.5,
                ease: "Power2.easeOut",
                stagger: 0.1,
                x: -(window.innerWidth - 100),
            })
            tl.fromTo("#preloader", {
                opacity: 1,
            }, {
                duration: 0.1,
                ease: "Power2.easeOut",
                stagger: 0.1,
                opacity: 0,
            })
            tl.fromTo(fogRef.current, {
                far: 0,
            }, {
                duration: 3,
                ease: "Power2.easeOut",
                far: 50,
            },
                "<0.5")
            tl.fromTo(sceneRef.current.children[sceneRef.current.children.length - 1].children[1].position, {
                z: 30,
            }, {
                duration: 1,
                ease: "Power2.easeIn",
                z: 0,
            },
                "<0.5")
        }
        const createRender = () => {
            rendererRef.current = new THREE.WebGLRenderer({ antialias: false });
            rendererRef.current.setClearColor(0x000000, 0);
            rendererRef.current.setPixelRatio(window.devicePixelRatio);
            rendererRef.current.setSize(container.current.clientWidth, container.current.clientHeight);
            rendererRef.current.toneMapping = THREE.ACESFilmicToneMapping;
            rendererRef.current.toneMappingExposure = 1;
            container.current.appendChild(rendererRef.current.domElement);
        }
        const manageRenderSize = () => {
            let appContainer = container.current
            return { width: appContainer.clientWidth, height: appContainer.clientHeight }
        }
        const createScene = () => {
            sceneRef.current = new THREE.Scene()
            // sceneRef.current.background = new THREE.Color("#E3D9CF")
            loadingManagerRef.current = new THREE.LoadingManager(() => {
                animate()
                introAnimation()
                triggerAnimation()
            }, (url, numberLoaded, allObj) => {
                dispatch(setPercentage(parseInt((numberLoaded / allObj) * 100)))
            })
        }

        const createCamera = () => {
            let fieldOfView = 22.89519413064574;
            const { width, height } = manageRenderSize()
            let aspectRatio = width / height;
            let nearPlane = 0.1;
            let farPlane = 3500000;
            cameraRef.current = new THREE.PerspectiveCamera(fieldOfView, aspectRatio, nearPlane, farPlane);
        }
        const render = () => {
            const delta = clock.getDelta();
            counter += (delta / 2)
            liquidPassRef.current.uniforms["u_time"].value = counter;
            liquidMaterialRef.current.uniforms["u_time"].value = counter;
            composerRef.current.render();
            // rendererRef.current.render(scene,camera)
        }
        const animate = () => {
            render()
            requestAnimationFrame(animate);
        }
        const loadHdr = () => {
            new RGBELoader(loadingManagerRef.current).load("https://mudakikwa.s3.eu-west-2.amazonaws.com/mudakikwa.com/goegap_1k.hdr", (texture) => {
                texture.mapping = THREE.EquirectangularReflectionMapping
                sceneRef.current.environment = texture
            })
            const light = new THREE.AmbientLight("#D1C2B7", 5); // soft white light
            sceneRef.current.add(light);
            fogRef.current = new THREE.Fog("#D1C2B7", 0, 40);
            sceneRef.current.fog = fogRef.current
        }
        const loadModel = () => {
            const glbLoader = new GLTFLoader(loadingManagerRef.current)
            const dracoLoader = new DRACOLoader(loadingManagerRef.current);
            dracoLoader.setDecoderPath("https://threejs.org/examples/jsm/libs/draco/");
            dracoLoader.preload();
            glbLoader.setDRACOLoader(dracoLoader);
            const rect = new THREE.TextureLoader(loadingManagerRef.current).load("https://mudakikwa.s3.eu-west-2.amazonaws.com/mudakikwa.com/Rectangle+22.webp")
            glbLoader.load("https://mudakikwa.s3.eu-west-2.amazonaws.com/mudakikwa.com/test/shader.glb", (gltf1) => {
                gltf1.scene.traverse((child1) => {
                    if (child1.name.includes("liquid") && child1.isMesh) {
                        liquidMaterialRef.current = liquidBackShader
                        child1.material = liquidMaterialRef.current
                    }
                    if (child1.name.includes("Plane") && child1.isMesh) {
                        let material = new THREE.MeshPhysicalMaterial({ color: "#1b1725", reflectivity: 1, transmission: 1, specularIntensity: 1, transparent: true })
                        material.map = rect
                        child1.material = material
                    }
                })

                sceneRef.current.add(gltf1.scene)
            })
            let maps = ["https://mudakikwa.s3.eu-west-2.amazonaws.com/mudakikwa.com/main.webp"
                , "https://mudakikwa.s3.eu-west-2.amazonaws.com/mudakikwa.com/guezshow.webp"
                , "https://mudakikwa.s3.eu-west-2.amazonaws.com/mudakikwa.com/leerecs.webp"
                , "https://mudakikwa.s3.eu-west-2.amazonaws.com/mudakikwa.com/sjcars.webp"
                , "https://mudakikwa.s3.eu-west-2.amazonaws.com/mudakikwa.com/logicoded.webp"
            ]
            let textures = []
            for (let i = 0; i < maps.length; i++) {
                const element = maps[i];
                textures.push(new THREE.TextureLoader(loadingManagerRef.current).load(element))
            }
            glbLoader.load("https://mudakikwa.s3.eu-west-2.amazonaws.com/mudakikwa.com/test/story.glb", (gltf) => {
                gltf.scene.traverse((child) => {
                    child.frustumCulled = false;
                    function handleVideo(name, url, child) {
                        if (child?.parent?.name === name && child.isMesh) {
                            let video = url
                            video.flipY = false
                            child.material = new THREE.MeshBasicMaterial({ map: video, reflectivity: 0.0 })
                        }
                    }
                    
                    handleVideo("reel", textures[0], child)
                    handleVideo("reel002", textures[1], child)
                    handleVideo("reel003", textures[2], child)
                    handleVideo("reel004", textures[3], child)
                    handleVideo("reel005", textures[4], child)
                })
                // let fieldOfView = 60;
                const { width, height } = manageRenderSize()
                let aspectRatio = width / height;
                gltf.cameras[0].aspectRatio = aspectRatio
                gltf.cameras[0].aspect = aspectRatio
                cameraRef.current = gltf.cameras[0]
                // camera.position.z+=1.5
                cameraRef.current.position.x -= .05
                cameraRef.current.updateProjectionMatrix();
                sceneRef.current.add(gltf.scene)
                postProcessing()
            })
        }
        const postProcessing = () => {
            composerRef.current = new EffectComposer(rendererRef.current);
            composerRef.current.addPass(new RenderPass(sceneRef.current, cameraRef.current));
            const effect1 = new ShaderPass(RGBShiftShader);
            effect1.uniforms["amount"].value = 0.001;
            composerRef.current.addPass(effect1);
            liquidPassRef.current = new ShaderPass(liquidShader);
            liquidPassRef.current.uniforms["u_time"].value = new THREE.Vector2(window.innerWidth, window.innerHeight);;
        }

        const onWindowResize = () => {
            if (cameraRef.current) {
                const { width, height } = manageRenderSize()
                let aspectRatio = width / height;
                cameraRef.current.aspectRatio = aspectRatio
                cameraRef.current.aspect = aspectRatio
                cameraRef.current.updateProjectionMatrix();
                rendererRef.current.setSize(window.innerWidth, window.innerHeight);
                composerRef.current.setSize(window.innerWidth, window.innerHeight);
                render();
            }
        };
        const handleLiquid = (e) => {
            if (e.detail) {
                document.querySelector("#webgl").style.zIndex = 50
                composerRef.current.removePass(liquidPassRef.current);
                composerRef.current.addPass(liquidPassRef.current);
            }
            else {
                document.querySelector("#webgl").style.zIndex = -1
                composerRef.current.removePass(liquidPassRef.current);
            }
        }
        window.addEventListener("liquid", handleLiquid)

        const cleanScene = () => {
            try {
                rendererRef.current.forceContextLoss()
                const cleanMaterial = material => {
                    material.dispose()

                    // dispose textures
                    for (const key of Object.keys(material)) {
                        const value = material[key]
                        if (value && typeof value === 'object' && 'minFilter' in value) {
                            value.dispose()
                        }
                    }
                }
                let disposeList = []
                loadingManagerRef.current = undefined
                sceneRef.current.traverse((child) => {
                    if (child.isMesh) {
                        disposeList.push({
                            geometry: child.geometry,
                            material: child.material,
                        })
                    }
                });
                disposeList.forEach(o => {
                    o.geometry.dispose()
                    cleanMaterial(o.material)
                });
                disposeList = []
                window.removeEventListener("liquid", handleLiquid)
                let canvas = document.querySelector('canvas');
                canvas.remove();
            }
            catch (e) {
                console.log("error", e)
            }
        }
        let currentLocation = 0
        const mouseMove = (e) => {
            const handleRotation = (y, x) => {
                let difference = e.clientX - currentLocation
                if (difference <= 0) {
                    return sceneRef.current.children[sceneRef.current.children.length - 1].children[1].rotation.y - THREE.MathUtils.degToRad(5)
                }
                else {
                    return sceneRef.current.children[sceneRef.current.children.length - 1].children[1].rotation.y + THREE.MathUtils.degToRad(5)
                }
            }

            if (sceneRef.current.children.length === 3 && moveElement) {
                gsap.to(sceneRef.current.children[sceneRef.current.children.length - 1].children[1].rotation, {
                    y: handleRotation(),
                    duration: 0.0,
                })
                currentLocation = e.changedTouches[0].clientX
            }
        }
        let moveElement = 0
        const mouseMoveDesktop = (e) => {
            const handleRotation = (y, x) => {
                let difference = e.clientX - currentLocation
                if (difference <= 0) {
                    return sceneRef.current.children[sceneRef.current.children.length - 1].children[1].rotation.y - THREE.MathUtils.degToRad(5)
                }
                else {
                    return sceneRef.current.children[sceneRef.current.children.length - 1].children[1].rotation.y + THREE.MathUtils.degToRad(5)
                }
            }

            if (sceneRef.current.children.length === 3 && moveElement) {
                let difference = e.clientX - currentLocation
                gsap.to(sceneRef.current.children[sceneRef.current.children.length - 1].children[1].rotation, {
                    y: handleRotation(),
                    duration: 0.0,
                })
                currentLocation = e.clientX
            }
        }
        const mousedown = (e) => {
            moveElement = 1
        }
        const mouseup = () => {
            moveElement = 0
        }
        const main = () => {
            createScene()
            createRender()
            createCamera()
            loadHdr()
            loadModel()
            window.removeEventListener('resize', onWindowResize);
            window.removeEventListener('touchmove', mouseMove);
            window.removeEventListener('mousemove', mouseMoveDesktop);
            window.removeEventListener('drag', mouseMoveDesktop);
            window.removeEventListener('mousedown', mousedown);
            window.removeEventListener('mouseup', mouseup);
            window.addEventListener('resize', onWindowResize);
            window.addEventListener('touchmove', mouseMove);
            window.addEventListener('mousemove', mouseMoveDesktop);
            window.addEventListener('drag', mouseMoveDesktop);
            window.addEventListener('mousedown', mousedown);
            window.addEventListener('mouseup', mouseup);
        }
        if (countRef.current === 1) {
            main()
        }
        return () => {
            window.removeEventListener('resize', onWindowResize);
            document.removeEventListener('touchmove', mouseMove);
            document.removeEventListener('mousemove', mouseMoveDesktop);
            document.removeEventListener('mousedown', mousedown);
            document.removeEventListener('mouseup', mouseup);
            sceneRef.current && cleanScene()
        }
    }, [])

    return (
        <div ref={container} id="webgl" draggable="true">
        </div>
    )
}
