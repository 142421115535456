import './index.scss'
import Reel from './reel/Reel'
import Dribble from './src/Dribble'
import Github from './src/Github'
import Linkedin from './src/Linkedin'
import Sound from './src/Sound'

import { useSelector, useDispatch } from 'react-redux'
import { setShowAbout } from "../redux/mainPageSlice"
import useWindowDimensions from '../hooks/useWindowDimensions.hook'
import DesktopComponent from './DesktopComponent'

export default function Layout({ children }) {
    const showReel = useSelector((state) => state.mainPageSliceReducer.showReel)
    const dispatch = useDispatch()
    const handleAbout = () => {
        dispatch(setShowAbout(true))
        const event = new CustomEvent('liquid', { detail: true });
        window.dispatchEvent(event);
    }

    const { width } = useWindowDimensions()
    return (
        <>
            {showReel && <Reel />}
            <DesktopComponent show={width > 600} />
            <div id="home" >
                <div className="d-flex">
                    <div className="sidebar" id="sidebar">
                        <div className="logo-overflow"><div className="logo opacity"><div className="mudakikwa-profilec rounded-circle"></div></div></div>
                        <div className="overflow-quote"><div className="quote-text"></div></div>
                        <div className="social-media overflow-quote ">
                            <a href="https://www.linkedin.com/in/mudakikwa-fred-267975232/" target="_blank" rel="noreferrer" >
                                <Linkedin />
                            </a>
                            <a href="https://github.com/mudakikwa" target="_blank" rel="noreferrer" >
                                <Github />
                            </a>
                            <a href="https://www.behance.net/mudakikwa/projects" target="_blank" rel="noreferrer" >
                                <Dribble />
                            </a>
                        </div>
                    </div>
                    <div className="main-page">
                        <div>
                            <div className="navbar-custom d-flex justify-content-between align-content-center">
                                <div className="navDiv"></div>
                                <div className="nav-link d-flex">
                                    <div className="nav-title">Home</div>
                                    <a href="#all-projects"><div className="nav-title">Projects</div></a>
                                    <div className="nav-title" onClick={handleAbout}>About</div>
                                    <a href="#footer"><div className="nav-title">Contact Us</div></a>
                                    <a href="https://mudakikwa.s3.eu-west-2.amazonaws.com/mudakikwa.com/Resume-Fred-Julio-Mudakikwa-latest.pdf" target="_blank" rel='noreferrer'><div className="nav-title">Resume</div></a>
                                </div>
                                <div className="sound d-flex ">
                                    <Sound />
                                </div>
                            </div>
                            <div className="nav-line"></div>
                        </div>

                        {children}

                    </div>
                </div>
            </div>
        </>
    )
}
