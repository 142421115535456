import './footer.scss'
import react from 'react'
import { InlineWidget } from "react-calendly";

function Footer() {

	return (
		<div className="footer" id="footer">
			<div className="container d-md-flex">
				<div className="col-md-6 px-0 left">
					<div></div>
					<div className="cta-footer">
						<div className="text-1">Have an idea?</div>
						<div className="text-2">Let's talk</div>
						<div className="line"></div>
					</div>
					<div className="info">
						<div className="email-section">
							fredmudakikwa@gmail.com
						</div>
						<div className="location">
							KN 5 Rd<br />
							Umuyenzi Plaza 2nd Floor<br />
							Gisimenti Remera<br />
							Kigali - Rwanda<br />
						</div>
					</div>
				</div>
				<div className="col-md-6 px-0 right">
					<div></div>
					<div className="calendly-container">
						<InlineWidget url="https://calendly.com/mudakikwastory/discovery-call" className='calendly' />
					</div>
					<div className="social-media">
						<div className="single-social-media">
							<a href="https://www.linkedin.com/in/fred-julio-mudakikwa-839368199/" target="_blank" rel="noopener noreferrer">Linkedin</a>
						</div>
						<div className="single-social-media"><a href="https://www.behance.net/mudakikwa/projects" target="_blank" rel="noopener noreferrer">Behance</a></div>
						<div className="single-social-media"><a href="https://github.com/mudakikwa" target="_blank" rel="noopener noreferrer">Github</a></div>
						<div className="single-social-media"><a href="https://linktr.ee/mudakikwa" target="_blank" rel="noopener noreferrer">Linktr</a></div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Footer
