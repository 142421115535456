import React, { useEffect } from 'react'


import Play from './src/Play'
import "./index.scss"
import { projectAnimation } from "./Animations"
import { setCurrentProject } from "../../../../redux/mainPageSlice"
import { useDispatch } from 'react-redux'

function Project(props) {
	const dispatch = useDispatch()
	const setProjectId = () => {
		dispatch(setCurrentProject(props.projectId))
	}
	useEffect(() => {
		let tl = projectAnimation(props.id, setProjectId)
		return () => tl.scrollTrigger.kill()
	}, [])

	const style = () => {
		if (window.innerWidth < 600) {
			return {
				backgroundImage: `url("${props.mobileImage}")`,
				backgroundColor: "rgba(0,0,0,0.65)",
				backgroundBlendMode: "overlay",
				backgroundSize: "cover",
				backgroundPosition: "center",
				backgroundRepeat: "no-repeat"
			}
		}
	}

	return (
		<div className="project" style={style()} >
			<div className="cta" id={props.id}>
				<div className="element-overflow">
					<div className={`project-big-text project-title unselectable`} id={`text1${props.id}`} style={{ opacity: 0 }}>{props.client} web</div>
				</div>
				<div className="project-big-text-2">
					<div className="element-overflow">
						<div className="project-title-span unselectable" id={`text2${props.id}`} style={{ opacity: 0 }}>Development</div>
					</div>
					<div className="d-flex">
						{props.tags.map((item, idx) => {
							return <div className={`single-process tag${props.id} unselectable`} key={idx}>{item}</div>
						})}
					</div>
					<div className="text-desc-project project-text  unselectable" id={`text3${props.id}`} style={{ opacity: 0 }}>{props.description}</div>
					<div className="d-flex ctas project-action">
						<div className="btn-red  unselectable" style={{ opacity: 0 }} id={`text3${props.id}`} >Case study</div>
						<a href={props.url} target="_blank" rel="noopener noreferrer">
							<div className="d-flex" id={`text3${props.id}`} style={{ opacity: 0 }}>
								<Play />
								<div className="play-text unselectable">View website</div>
							</div>
						</a>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Project
